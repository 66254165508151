@import "../../assets/styles/utils";

.ny-banner{
  position: relative;
  @include res(margin-bottom,1.6rem,(xs:.9rem));
  @include res(height,30vw,(md:30vw,xs:60vw));
  .bg-img{
    height: 100%;
    overflow: hidden;
    @include res(width,100%,(xs:130%));
    @include res(border-bottom-right-radius,$bg-radius);
    @include res(transform,skew($bg-skew-left) translateX(-15%),(xs:skew($bg-skew-left) translateX(-20%)));
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(#022c5b,.1);
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-2.6rem) translateY(-1.1rem);
      @include res(border-bottom-right-radius,$bg-radius);
      @include res(transform,translateX(-2rem) translateY(-.9rem),(xs:translateX(-.8rem) translateY(-.5rem)));
    }
    b{
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      @include res(transform,skew($bg-skew) translateX(10%),(xs:skew($bg-skew) translateX(15%)));
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.1);
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .bg-blue{
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    overflow: hidden;
    
    @include res(width,50%,(xs:100%));
    @include res(height,85.5%,(xs:100%));
    @include res(transform,translateX(0),(xs:translateX(4%)));
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 40%;
      background-image: linear-gradient(to top, rgba(235,247,254,1),rgba(235,247,254,0));
      position: absolute;
      bottom: -1px;
      right: -1px;
    }
    b{
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      transform: skew($bg-skew-left) translateX(9%);
      @include res(border-bottom-right-radius,$bg-radius);
    }
    .video{
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      transform: skew($bg-skew-left) translateX(9%);
      background: #0076c0;
      @include res(border-bottom-right-radius,$bg-radius);
      video{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: .2;
        @include res(border-bottom-right-radius,$bg-radius);
      }
    }
  }
  .title{
    position: absolute;
    width: 100%;
    color: #fff;
    z-index: 2;
    left: 0;
    @include res(font-size,.9rem,$font90);
    @include res(bottom,1.1rem,(md:1.2rem,sm:1.2rem,xs:.6rem));
  }
}