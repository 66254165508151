@import "../../assets/styles/utils";

.footer-contact{
  position: relative;
  z-index: 1;
  @include res(padding-top,.7rem);
  @include res(margin-top,1.9rem,(xs:1rem));
  &::after{
    content: '';
    display: block;
    background-image: linear-gradient(to right,#a6d6f4,#e7f5fd);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-top-right-radius: 1.5rem;
    @include res(transform,skew($bg-skew) translateX(-13%));
    @include res(width,100%);
    @include res(height,100%);
  }
  .text{
    flex-shrink: 0;
    @include res(padding-right,null,(xs:1rem));
    .t{
      h2{
        @include res(font-size,.35rem,(md:.45rem,sm:.55rem,xs:.4rem));
        @include res(margin-bottom,.15rem);
      }
    }
    .link{
      position: relative;
      z-index: 1;
      @include res(margin-top,.6rem);
      @include res(padding-top,.2rem);
      @include res(padding-bottom,.35rem);
      @include res(padding-left,0,(xs:.5rem));
      &::after{
        content: '';
        display: block;
        background-image: linear-gradient(to right,rgba(255,255,255,.5),rgba(255,255,255,.0));
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        border-top-left-radius: .5rem;
        @include res(transform,skew($bg-skew-left) translateX(-.93rem),(md:skew($bg-skew-left) translateX(-.65rem),xs:skew($bg-skew-left) translateX(0)));
        @include res(width,40%,(xs:100%));
        @include res(height,100%);
      }
    }
  }
  .img{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include res(right,26.3vw,(md:20vw));
    @include res(display,block,(sm:none));
    img{
      display: block;
      height: auto;
      @include res(width,2.14rem,(md:2.5rem));
    }
  }
}
.contact-dialog{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
  z-index: 1000;
  display: none;
  .flex{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .form{
      position: relative;
      border-radius: .5rem 1.5rem;
      background-image: linear-gradient(to bottom,#45a0cb,#deeef6);
      @include res(width, 50%,(md:60%,sm:70%,xs:90%));
      @include res(padding-top,.5rem);
      @include res(padding-right,.9rem,(xs:.3rem));
      @include res(padding-bottom,.5rem);
      @include res(padding-left,.9rem,(xs:.3rem));
      .close-btn{
        color: $color-main;
        background-color: #fff;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        @include res(top, .4rem);
        @include res(right, .9rem,(xs:.5rem));
        @include res(width, .5rem,(md:.6rem,sm:.8rem,xs:.6rem));
        @include res(height, .5rem,(md:.6rem,sm:.8rem,xs:.6rem));
        .iconfont{
          display: block;
          transition: transform .3s;
          @include res(font-size, .16rem,(md:.26rem,sm:.36rem,xs:.3rem));
        }
        &:hover{
          .iconfont{
            transform: rotate(90deg);
          }
        }
      }
      .form-title{
        color: #fff;
        @include res(font-size,.4rem,$font40);
        @include res(margin-bottom,.35rem);
      }
      form{
        justify-content: space-between;
        flex-wrap: wrap;
        @include res(display,flex,(sm:block));
        .form-item{
          width: 100%;
          position: relative;
          @include res(margin-bottom,.15rem);
          &.half{
            @include res(width,49%,(sm:100%));
          }
          input,textarea{
            width: 100%;
            border-radius: .1rem;
            border: none;
            background-color: #fff;
            line-height: 1.6;
            color: #000;
            transition: box-shadow .3s;
            @include res(padding,.2rem .15rem);
            &::-webkit-input-placeholder{
              color: #075699;
            }
            &:-moz-placeholder{
              color: #075699;
            }
            &::moz-placeholder{
              color: #075699;
            }
            &:-ms-input-placeholder{
              color: #075699;
            }
            &:focus{
              box-shadow: 0 0 .15rem rgba(255,255,255,.5);
            }
          }
          .captcha-img{
            position: absolute;
            height: 100%;
            top: 0;
            border-radius: .1rem;
            overflow: hidden;
            display: flex;
            align-items: center;
            cursor: pointer;
            @include res(right, .1rem);
            img{
              display: block;
              width: auto;
              @include res(height,70%);
            }
          }
        }
        .form-btn{
          width: 100%;
          text-align: right;
          @include res(margin-top,.3rem);
          .common-more{
            border: none;
            background: none;
            cursor: pointer;
            color: $color-text;
            text-transform: uppercase;
            span{
              @include res(font-size,.14rem,(md:.22rem,sm:.3rem,xs:.22rem));
            }
          }
        }
      }
    }
  }
}
footer{
  position: relative;
  .zixun{
    position: fixed;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-70%);
    transition: opacity .3s,visibility .3s,transform .3s;
    cursor: pointer;
    @include res(right,.55rem,(md:.5rem,xs:.1rem));
    @include res(bottom,110px,(md:100px,sm:95px));
    .icon-drip-full{
      color: $color-main;
      @include res(font-size,.74rem,(md:64px,sm:50px));
    }
    .icon-Whatapp{
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate3d(-50%,-50%,0);
      color: #fff;
      @include res(font-size,.36rem,(md:30px,sm:26px));
    }
    &.active{
      opacity: 1;
      visibility: visible;
      transform: translateY(-50%);
    }
    &.abs{
      position: absolute;
      bottom: auto;
      @include res(top,-80px,(md:-70px,sm:-55px));
    }
  }
  .backtop{
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    color: $color-main;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-50%);
    box-shadow: 0 0 .1rem rgba(0,0,0,.1);
    transition: opacity .3s,visibility .3s;
    z-index: 10;
    @include res(right,.6rem,(xs:.2rem));
    @include res(bottom,60px);
    @include res(width,.6rem,(md:50px,sm:40px));
    @include res(height,.6rem,(md:50px,sm:40px));
    &.active{
      opacity: 1;
      visibility: visible;
    }
    &.abs{
      position: absolute;
      top: 0;
      bottom: auto;
    }
    .iconfont{
      line-height: .8;
      display: inline-block;
      transition: transform .3s;
      @include res(font-size,.24rem,(md:.34rem,sm:.44rem,xs:.3rem));
    }
    span{
      @include res(font-size,.14rem,(md:.24rem,sm:.3rem,xs:.24rem));
    }
    &:hover{
      .iconfont{
        transform: translateY(-.02rem);
      }
    }
  }
  .footer-bg{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    // background: url(../../assets/images/footer_bg.jpg) no-repeat center center #0076c0;
    background: #0076c0;
    background-size: cover;
    border-top-right-radius: 1.2rem;
    @include res(width,100%);
    img,video{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: .2;
    }
  }
  .footer{
    align-items: stretch;
    justify-content: space-between;
    color: #fff;
    @include res(display,flex,(sm:block));
    @include res(padding-top,1rem,(xs:.6rem));
    @include res(padding-bottom,.3rem);
    .footer-nav-pc{
      flex-shrink: 0;
      justify-content: space-between;
      @include res(width,76%,(md:80%,sm:100%));
      @include res(display,flex,(xs:none));
      .nav-item{
        flex-shrink: 0;
        @include res(max-width,26%,(md:30%,sm:25%));
        .title{
          @include res(font-size, .22rem,(md:.34rem,sm:.42rem,xs:.38rem));
          @include res(margin-bottom,.15rem);
        }
        .sub-nav{
          @include res(margin-bottom,.2rem);
          a{
            display: block;
            color: rgba(255,255,255,.75);
            transition: all .3s;
            line-height: 1;
            @include res(margin-bottom,.1rem,(md:.2rem));
            @include res(font-size, .18rem,(md:.24rem,sm:.3rem,xs:.3rem));
            &:hover{
              color: #fff;
            }
          }
        }
      }
    }
    .footer-nav-mob{
      @include res(display,none,(xs:block));
      ul{
        align-items: stretch;
        justify-content: space-between;
        @include res(display,flex,(xs:block));
        li{
          flex-grow: 1;
          @include res(max-width, 20%,(xs:100%));
          @include res(border-bottom,null,(xs:1px solid rgba(255,255,255,.1)));
          .title{
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include res(margin-bottom, .15rem,(xs:0));
            @include res(font-size, .22rem,(md:.34rem,sm:.42rem,xs:.32rem));
            a{
              flex-grow: 1;
              @include res(margin-left,0,(xs:.1rem));
              @include res(padding-top,0,(xs:.2rem));
              @include res(padding-bottom,0,(xs:.2rem));
            }
            .iconfont{
              transition: all .3s;
              flex-shrink: 0;
              align-items: center;
              justify-content: center;
              @include res(display,none);
              @include res(width,1rem);
            }
          }
          .sub-nav{
            zoom: 1;
            @include res(display,block,(xs:none));
            @include res(font-size,.18rem,(md:.28rem,sm:.34rem,xs:.28rem));
            @include res(padding-bottom, .05rem);
            a{
              opacity: .75;
              display: block;
              transition: all .3s;
              @include res(padding-left,0,(xs:.3rem));
              @include res(padding-top,0,(xs:.1rem));
              @include res(padding-bottom,0,(xs:.1rem));
              
              &:hover{
                opacity: 1;
              }
            }
            &::after{
              content: '';
              clear: both;
              display: block;
            }
          }
          &:last-child{
            .sub-nav{
              align-items: stretch;
              justify-content: flex-start;
              flex-wrap: wrap;
              @include res(display,flex,(xs:none));
              a{
                float: left;
                @include res(width,50%);
              }
            }
          }
          &.active{
            .title{
              .iconfont{
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
    .footer-info{
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      flex-shrink: 0;
      @include res(display,flex,(xs:block));
      @include res(width,null,(xs:100%));
      @include res(text-align,right,(xs:center));
      @include res(margin-top,0,(sm:.5rem));
      .info{
        @include res(margin-bottom,0,(sm:.5rem,xs:.3rem));
        .email{
          @include res(font-size,.28rem,(md:.3rem,sm:.4rem,xs:.32rem));
          @include res(margin-bottom,.05rem);
        }
      }
      .social{
        a{
          @include res(margin-left,.05rem);
        }
        .iconfont{
          @include res(font-size,.4rem,(md:.6rem,sm:.8rem,xs:.7rem));
        }
      }
      
    }
  }
  .copyright{
    position: relative;
    color: #fff;
    @include res(text-align,right,(xs:center));
    @include res(font-size,.16rem,(md:.26rem,sm:.36rem,xs:.24rem));
    @include res(padding-top,.25rem,(xs:.2rem));
    @include res(padding-bottom,.25rem,(xs:.2rem));
    &::after{
      content: '';
      display: block;
      position: absolute;
      bottom: 100%;
      background: url(../../assets/images/footer_bg2.png) no-repeat top right;
      background-size: auto 100%;
      @include res(right,.9rem,(md:.7rem,xs:.1rem));
      @include res(width,100%);
      @include res(height,.27rem);
    }
  }
}
