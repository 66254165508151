@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/swiper-bundle.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import './animate.css';
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'Poppins-Bold';
  src: url('../fonts/Poppins-Bold.woff') format('woff'),
    url('../fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-Regular';
  src: url('../fonts/Poppins-Regular.woff') format('woff'),
    url('../fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}
html{
  font-size: 62.5%;
}
body {
  font-family: "Poppins-Regular","PingFang SC","Microsoft YaHei";
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #ebf7fe;
  @include res(font-size, 12px);
}
.comp-root{
  overflow-x: hidden;
  @include res(font-size,.18rem,(md:.28rem,sm:.34rem,xs:.28rem));
}

.bold{
  font-family: 'Poppins-Bold';
}

//主体内容部分宽度
.container {
  @include res(padding-left,1.2rem,(md:1rem,xs:.3rem));
  @include res(padding-right,1.2rem,(md:1rem,xs:.3rem));
}

.top_low {
  transform: translateY(5vw);
  opacity: 0;
  transition: transform 3e3ms cubic-bezier(0.19, 1, 0.22, 1),
              opacity 3e3ms cubic-bezier(0.19, 1, 0.22, 1);
  &.is-inview{
    transform: translateY(0);
    opacity: 1;
  }
}

.is-inview{
  .top_low {
    transform: translateY(0);
    opacity: 1;
  }
}


.common-more{
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  @include res(padding-right,.2rem,(md:.3rem,sm:.4rem,xs:.3rem));
  @include res(padding-top,.08rem,(md:.12rem,sm:.2rem,xs:.1rem));
  @include res(padding-bottom,.08rem,(md:.12rem,sm:.2rem,xs:.1rem));
  span{
    font-family: 'Poppins-Bold';
    display: block;
    white-space: nowrap;
    @include res(width,.92rem,(md:1.6rem,sm:2.3rem,xs:1.7rem));
    @include res(font-size,.14rem,(md:.24rem,sm:.34rem,xs:.26rem));
    @include res(margin-right,.65rem,(xs:.4rem));
    @include res(padding-top,.02rem,(md:.08rem));
    @include res(transform,translateX(.04rem),(md:translateX(0)));
  }
  .icon{
    position: relative;
    overflow: hidden;
    &::before,&::after{
      content: '\e601';
      font-family: 'iconfont';
      transition: transform .4s;
      display: inline-block;
      @include res(font-size,.2rem,(md:.36rem,sm:.44rem,xs:.38rem));
    }
    &::before{
      position: absolute;
      top: 0;
      right: 100%;
    }
  }
  svg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    path{
      stroke: #075699;
      stroke-width: 2px;
      fill: transparent;
      transition:stroke-dashoffset .6s;
      @include res(stroke-dasharray,(300%,300%));
      @include res(stroke-dashoffset,-155%);
    }
  }
  &:hover{
    .icon{
      &::before,&::after{
        transform: translateX(100%);
      }
    }
    svg{
      path{
        stroke-dashoffset: 0;
      }
    }
  }
}

.common-title{
  font-family: 'Poppins-Bold';
  line-height: 1;
  @include res(margin-bottom,.2rem);
  @include res(font-size,.8rem,(md:.9rem,sm:1rem,xs:.6rem));
}