@import "../../assets/styles/utils";

header{
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    z-index: 1000;
    width: 100%;
    transition: all .4s;
    @include res(top,.5rem,(xs:.2rem));
    @include res(padding-left,.8rem,(xs:7%));
    @include res(padding-right,.75rem,(xs:4%));
    @include res(height,$header-height-base,$header-height-ratio);
    &.show-search{
      .logo{
        @include res(opacity,null,(xs:0));
        @include res(visibility,null,(xs:hidden));
      }
      .right{
        &::before{
          @include res(width,null,(md:50vw,xs:calc(100vw - 15%)));
        }
        .right-cont{
          opacity: 0;
          visibility: hidden;
          @include res(transform,translateX(.5rem));
        }
        .header-search{
          opacity: 1;
          visibility: visible;
          @include res(transform,translateX(0));
        }
      }
    }
    &.scroll{
      &.show-search{
        .right{
          &::before{
            background-color: rgba(#00a2e2, .7);
            @include res(width,calc(100vw - 1.45rem),(md:calc(100vw - 1.3rem),xs:calc(100vw - 15%)));
          }
        }
      }
      .logo{
        img{
          @include res(width,1.5rem,(md:1.6rem,sm:1.8rem,xs:1.6rem));
          &.logo-white{
            opacity: 1;
          }
          &.logo-color{
            opacity: 0;
          }
        }
      }
      .right{
        &::before{
          background-color: rgba(#00a2e2, .7);
          @include res(width,calc(100vw - 1.45rem),(md:calc(100vw - 1.3rem),xs:calc(100vw - 15%)));
        }
      }
    }
    &.trans{
      top: 0;
      transform: translateY(-100%);
    }
    
    .logo{
      position: relative;
      transition: all .3s;
      img{
        display: block;
        height: auto;
        transition: all .6s;
        @include res(width,1.96rem,(md:2.5rem,sm:3rem,xs:2rem));
        &.logo-white{
          opacity: 0;
        }
        &.logo-color{
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    .right{
      height: 100%;
      position: relative; 
      @include res(padding-left,.35rem);
      @include res(padding-right,.15rem,(md:.2rem,xs:.15rem));
      &::before{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        background-color: #00a2e2;
        transform: skew(27deg);
        border-top-left-radius: .2rem;
        border-bottom-right-radius: .2rem;
        border-top-right-radius: .3rem;
        border-bottom-left-radius: .3rem;
        transition: width .6s,background-color .6s;
        backdrop-filter: blur(5px);
      }
      .right-cont{
        height: 100%;
        align-items: center;
        transition: all .6s;
        @include res(display,flex);
        .nav{
          height: 100%;
          @include res(margin-right,.3rem);
          @include res(display,block,(md:none));
          ul{
            position: relative;
            align-items: center;
            height: 100%;
            @include res(display,flex);
            li{
              height: 100%;
              position: relative;
              .title{
                height: 100%;
                color: #fff;
                transition: color .3s;
                font-family: 'Poppins-Bold';
                position: relative;
                @include res(font-size,.2rem);
                a{
                  height: 100%;
                  display: flex;
                  align-items: center;
                  @include res(padding-left,.15rem);
                  @include res(padding-right,.15rem);
                }
                span{
                  position: absolute;
                  left: 50%;
                  transform: translate3d(-50%,0,0);
                  opacity: 0;
                  @include res(bottom,22%);
                  &::before{
                    content: '\e63a';
                    font-family: 'iconfont';
                    color: #fff;
                    @include res(font-size,.08rem);
                  } 
                }
              }
              .sub-nav{
                position: absolute;
                top: 100%;
                left: -.1rem;
                background-color: rgba(#00a2e2, .7);
                color: #fff;
                white-space: nowrap;
                text-wrap: nowrap;
                backdrop-filter: blur(5px);
                transform: translateY(.3rem);
                opacity: 0;
                visibility: hidden;
                transition: all .3s;
                @include res(border-radius, .1rem);
                @include res(padding, .1rem 0);
                a{
                  display: block;
                  transition: background-color .3s;
                  @include res(padding, .1rem .2rem);
                  &:hover{
                    background-color: #1482cf;
                  }
                }
              }
              &.hover{
                .sub-nav{
                  transform: translateY(.2rem);
                  opacity: 1;
                  visibility: visible;
                }
              }
              &.active{
                .title{
                  span{
                    // transition: opacity .3s ease, bottom .5s ease;
                    opacity: 1;
                    @include res(bottom,8%);
                  }
                }
              }
            }
          }
        }
        .open-search{
          color: #fff;
          cursor: pointer;
          transition: background-color .4s $anime-bezier;
          @include res(padding,.1rem);
          .iconfont{
            transition: all .3s;
            display: block;
            @include res(font-size,.26rem,(md:.4rem,sm:.5rem,xs:.4rem));
          }
          &:hover{
            .iconfont{
              transform: scale(1.2);
            }
          }
        }
        .language{
          color: #fff;
          position: relative;
          z-index: 1;
          cursor: pointer;
          @include res(margin-left,.2rem);
          .language-bg{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            background-color: #1482cf;
            transform: skew(27deg);
            border-radius: .1rem .2rem;
            transition: all .3s;
          }
          .language-title{
            display: flex;
            align-items: center;
            @include res(padding-left,.35rem,(md:.35rem));
            @include res(padding-right,.25rem,(md:.25rem));
            @include res(padding-top,.1rem);
            @include res(padding-bottom,.1rem);
            span{
              font-weight: bold;
              @include res(font-size,.17rem,(md:.27rem,sm:.37rem,xs:.26rem));
            }
            .iconfont{
              line-height: 0.8;
              @include res(font-size,.2rem,(md:.3rem,sm:.4rem,xs:.3rem));
              @include res(margin-left,.05rem);
            }
          }
          .language-sub{
            position: absolute;
            top: 100%;
            width: 100%;
            color: #fff;
            opacity: 0;
            visibility: hidden;
            transition: all .3s;
            transform: translateY(-.1rem);
            li{
              text-align: center;
              @include res(padding,.05rem);
              a{
                opacity: .7;
                transition: all .3s;
                &:hover{
                  opacity: 1;
                }
              }
            }
          }
          &.active{
            .language-bg{
              transform: skew(0deg);
              border-radius: .1rem;
            }
            .language-sub{
              transition-delay: .2s;
              opacity: 1;
              visibility: visible;
              transform: translateY(0);
            }
          }
        }
        .hamburger{
          @include res(margin-right,.2rem,(xs:.1rem));
          @include res(margin-left,.2rem,(xs:.1rem));
          @include res(display,none,(md:block));
        }
      }
      
      .header-search{
        position: absolute;
        width: 100%;
        right: 0;
        top: 0;
        z-index: 3;
        display: flex;
        align-items: center;
        transition: all .6s;
        opacity: 0;
        visibility: hidden;
        @include res(transform,translateX(.5rem));
        @include res(width,100%,(md:50vw,sm:90vw));
        @include res(padding-left,.3rem);
        @include res(padding-right,.3rem);
        @include res(height,100%);
        form{
          flex-grow: 1;
          display: flex;
          align-items: center;
          input{
            flex-grow: 1;
            border: none;
            color: #fff;
            background: rgba(255,255,255,.1);
            border-radius: .1rem;
            @include res(padding-left, .1rem);
            @include res(height,.5rem,(md:.6rem,sm:.7rem,xs:.6rem));
            @include res(font-size, .18rem,(md:16px));
          }
          button{
            background: transparent;
            border-radius: 50%;
            border: none;
            cursor: pointer;
            color: #fff;
            transition: all .4s;
            @include res(margin-left,.1rem);
            @include res(width,.4rem,(md:40px));
            @include res(height,.4rem,(md:40px));
            .iconfont{
              transition: all .3s;
              display: block;
              @include res(font-size,.26rem,(md:.4rem,sm:.5rem,xs:.4rem));
            }
            &:hover{
              .iconfont{
                transform: scale(1.2);
              }
            }
          }
        }
        .header-search-close{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          font-weight: bold;
          color: #fff;
          cursor: pointer;
          @include res(width, .5rem,(md:40px));
          @include res(height,.5rem,(md:40px));
          &:hover{
            .iconfont{
              transform: rotate(90deg);
            }
          }
          .iconfont{
            display: inline-block;
            transition: all .4s;
          }
        }
      }
    } 
  }
  .mob-header{
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 998;
    height: 100vh;
    overflow-x: hidden;
    background: #fff;
    background-size: cover;
    transition: all $anime-duration;
    @include res(padding-top, 3rem,(xs:1.3rem));
    @include res(padding-bottom, 1.5rem,(xs:.5rem));
    @include res(padding-left, 1rem,(xs:.5rem));
    @include res(padding-right, 1rem,(xs:.5rem));
    @include res(width,60%,(sm:80%,xs:100%));
    &.show{
      transform: translateX(-100%);
    }
    ul{
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      li{
        border-bottom: 1px solid rgba(0,0,0,.1);
        letter-spacing: .01rem;
        &.active{
          .title .iconfont{
            transform: rotate(90deg);
          }
        }
        .title{
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;
          font-family: 'Poppins-Bold';
          @include res(line-height,1.4rem,(xs:1rem));
          @include res(padding-left,.3rem);
          @include res(display,flex);
          @include res(font-size,.4rem,(sm:.5rem,xs:.36rem));
          a{
            flex-grow: 1;
          }
          .iconfont{
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            transition: all $anime-duration;
            transform-origin: center;
            @include res(display,none);
            @include res(font-size,.4rem,(xs:.34rem));
            @include res(width,1.4rem,(xs:1rem));
            @include res(height,1.4rem,(xs:1rem));
          }
        }
        .sub-nav{
          display: none;
          @include res(padding-left, .3rem);
          @include res(padding-right, .3rem);
          @include res(padding-bottom,.3rem);
          &::after{
            content: '';
            display: block;
            clear: both;
          }
          a{
            display: block;
            @include res(margin-bottom,.2rem);
          }
        }
        &:nth-child(2){
          .sub-nav a{
            width: 100%;
          }
        }
      }
    }
  }
  .menu-mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    top:0;
    left: 0;
    z-index: 997;
    display: none;
  }
}