@font-face {
  font-family: "iconfont"; /* Project id 4574980 */
  src: url('iconfont.woff2?t=1728882376878') format('woff2'),
       url('iconfont.woff?t=1728882376878') format('woff'),
       url('iconfont.ttf?t=1728882376878') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Whatapp:before {
  content: "\e616";
}

.icon-linkedin-fill:before {
  content: "\eab1";
}

.icon-guanbi:before {
  content: "\e61a";
}

.icon-sousuo_2:before {
  content: "\e6d2";
}

.icon-map-filling:before {
  content: "\e68c";
}

.icon-Youtube-fill:before {
  content: "\e883";
}

.icon-facebook:before {
  content: "\e630";
}

.icon-instagram-fill:before {
  content: "\e768";
}

.icon-twiter:before {
  content: "\e65e";
}

.icon-drip-full:before {
  content: "\e63a";
}

.icon-small-right:before {
  content: "\e600";
}

.icon-jt_right:before {
  content: "\e601";
}

.icon-small-left:before {
  content: "\e602";
}

.icon-line_talk:before {
  content: "\e603";
}

.icon-small-btm:before {
  content: "\e884";
}

.icon-small-top:before {
  content: "\e885";
}

